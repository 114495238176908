import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Grid, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BaseTable, { Column } from 'react-base-table';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import Auth from '@aws-amplify/auth';
import FuzzySearch from 'fuzzy-search';
import { v4 as uuid } from 'uuid';
import { contextState } from '../ContextProvider';
import ConfirmationModal from '../organisms/ConfirmationModal/ConfirmationModal';
import Loading from '../atoms/Loading';
import {
  GetOpsLocations,
  GetLocationRoutes,
  GenerateReceivingInvoicesPDF,
} from '../libs/GraphQL';
import { yyyymmdd } from '../libs/date_convert';
import styledComponent from '../utils/styledComponent';

import 'react-base-table/styles.css';
import 'react-toastify/dist/ReactToastify.css';

const toastDefaultOptions = {
  className: 'prettyToast',
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'light',
};
const regionsList = [
  { value: 'MW', label: 'MW' },
  { value: 'NE', label: 'NE' },
  { value: 'WC', label: 'WC' },
  { value: 'S', label: 'S' },
];
const operatingModelList = ['BBS', 'Consignment', 'Traditional'];

const StyledSection = styledComponent('section', {
  '& .datePickerLabel': {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  '& h1 small': {
    marginLeft: '12px',
  },
  '& .invoiceDatePickerWrapper .react-date-picker__button': {
    borderRadius: '4px',
  },
  '& .react-date-picker__calendar': {
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(100%)',
  },
  '& .invoicePageHeader': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 20px',
    borderBottom: '1px solid #eee',
  },
  '& .controlPanel': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 20px',
  },
  '& .searchBoxes': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
    flexGrowth: 1,
  },
  '& .searchBoxesLabel': {
    fontSize: '16px',
    width: '70px',
  },
  '& .searchBox': {
    display: 'flex',
    padding: '12px 16px',
    gap: '8px',
    width: '335px',
    height: '40px',
    background: '#eeeeee',
    borderRadius: '4px',
    flex: 'none',
    flexGrow: 0,
    border: 'none',
  },
  '& .customFooter': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  '& .customFooter p': {
    margin: '4px 0 10px',
  },
  '& .receivingInvoicesTable .BaseTable__row-cell-text': {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: '8px 0',
  },
  '& .dropOffDateBtnLarge': {
    marginBottom: '15px',
    width: '100%',
  },
});

export default function ReceivingInvoices({ history }) {
  const { contextData } = contextState();
  const [dropOffDate, setDropOffDate] = useState({
    current: new Date(),
    search: yyyymmdd(new Date(), '-'),
  });
  const [data, setData] = useState({
    result: [],
    initResult: [],
  });
  const [wholesaleLocations, setWholesaleLocations] = useState([]);
  const [selectedRows, setSelectedRows] = useState({
    keys: [],
  });
  const [filterSettings, setFilterSettings] = useState({
    retailerFilter: [],
    regionFilter: [],
    fulfillmentCenterFilter: [],
  });
  const [sortBy, setSortBy] = useState({});
  const [showData, setShowData] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [locationIdSearchValue, setLocationIdSearchValue] = useState('');
  const [routeIdSearchValue, setRouteIdSearchValue] = useState('');
  const [fulfillmentCentersList, setFulfillmentCentersList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [batchId, setBatchId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const getUniqueValues = (array, field) => {
    return array.map((item) => item[field]).filter((value) => value !== null);
  };

  const getUserEmail = async () => {
    try {
      const currentSession = await Auth.currentAuthenticatedUser();
      const email = currentSession.signInUserSession.idToken.payload.email;
      if (!email.includes('@')) {
        throw new Error('There is no email present');
      } else {
        setUserEmail(email);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    GetOpsLocations({ type: 'WHOLESALE', status: ['LIVE'] })
      .then((response) => {
        const locations = response.data.opsLocations.locations;
        // Filter by locations that have operatingModel set as BBS/Traditional/Consignment
        const result = locations.filter((item) =>
          operatingModelList.includes(item.operatingModel),
        );

        setWholesaleLocations(result);

        const centers = [
          ...new Set(getUniqueValues(result, 'fulfillmentCenter')),
        ];
        let formattedCenters = centers.map((center) => ({ value: center }));
        formattedCenters = formattedCenters.sort((a, b) =>
          a.value.localeCompare(b.value),
        );
        setFulfillmentCentersList(formattedCenters);

        const retailers = [...new Set(getUniqueValues(result, 'retailer'))];
        let formattedRetailers = retailers.map((retailer) => ({
          value: retailer,
        }));
        formattedRetailers = formattedRetailers.sort((a, b) =>
          a.value.localeCompare(b.value),
        );
        setRetailerList(formattedRetailers);

        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err.errors) {
          err.errors.forEach((error) => {
            toast.error(error.message);
          });
        } else {
          console.log(err);
          toast.error('Unable to load locations - Please try again later');
        }
      });
    getUserEmail();
  }, []);

  // Apply filters whenever filterSettings change
  useEffect(() => {
    applyFilters();
  }, [filterSettings]);

  const customCheckBoxHeader = () => {
    return (
      <input
        type="checkbox"
        name="selectAllCheckbox"
        value="all"
        className="headerCheckbox"
        onChange={() => undefined} // avoid errors about controlled input without onChange
      />
    );
  };

  const customTableFooter = () => {
    return (
      <div className="customFooter">
        <p>{`${selectedRows.keys.length} out of ${data.result.length} locations selected`}</p>
      </div>
    );
  };

  const cellRenderer = ({ rowData }) => (
    <input
      type="checkbox"
      name="singleCheckbox"
      value={rowData.id}
      checked={rowData.checked || false}
      className="rowCheckbox"
      onChange={() => undefined} // avoid errors about controlled input without onChange
      uniqueid={rowData.id}
    />
  );

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const selectedRowKeys = isChecked ? data.result.map((item) => item.id) : [];
    const updatedData = data.result.map((item) => ({
      ...item,
      checked: isChecked,
    }));
    const updatedInitData = data.initResult.map((item) => {
      // Check if the item exists in the filtered result array
      const found = data.result.find(
        (filteredItem) => filteredItem.id === item.id,
      );
      // Update the checked status based on the existence in the filtered result
      return { ...item, checked: found ? isChecked : false };
    });
    setSelectedRows((prevState) => ({
      ...prevState,
      keys: isChecked ? selectedRowKeys : [],
    }));

    setData((prevState) => ({
      ...prevState,
      initResult: updatedInitData,
      result: updatedData,
    }));
  };

  const generateInvoices = async () => {
    try {
      const InvoiceGenerationLocationsInput = [];
      const uniqueId = uuid();
      setBatchId(uniqueId);
      for (let i = 0; i < selectedRows.keys.length; i++) {
        InvoiceGenerationLocationsInput.push({
          locationId: selectedRows.keys[i],
          dropOffDate: dropOffDate.search,
        });
      }
      const invoiceInfo = {
        totalCount: selectedRows.keys.length,
        locations: InvoiceGenerationLocationsInput,
        batchId: uniqueId,
        userEmail,
      };
      const response = await GenerateReceivingInvoicesPDF(invoiceInfo);
      if (
        response.data.generateReceivingInvoicesPDF === invoiceInfo.totalCount
      ) {
        toast.success(
          `Successfully submitted request for generating invoices. You will receive an email with the invoices shortly.`,
          toastDefaultOptions,
        );
      } else {
        toast.error(
          `Error generating invoices. Please try again later.`,
          toastDefaultOptions,
        );
      }
    } catch (e) {
      console.error(e);
      toast.error(
        `Error generating invoices. Please try again later.`,
        toastDefaultOptions,
      );
    }
  };

  const onConfirmInvoiceGenerate = () => {
    setShowConfirmModel(false);
    generateInvoices();
    handleSelectAll({ target: { checked: false } });
    setShowData(false);
    setShowMessage(true);
  };

  const handleSearching = (e) => {
    let searchedData = [];
    if (e.target.name === 'locationIdSearchBox') {
      setLocationIdSearchValue(e.target.value);
      setRouteIdSearchValue('');
      const locationSearcher = new FuzzySearch(
        Object.values(data.initResult),
        ['id'],
        {
          caseSensitive: false,
        },
      );
      searchedData = locationSearcher.search(e.target.value);
    } else if (e.target.name === 'routeIdSearchBox') {
      setRouteIdSearchValue(e.target.value);
      setLocationIdSearchValue('');
      const routeSearcher = new FuzzySearch(Object.values(data.initResult), [
        'route',
      ]);
      searchedData = routeSearcher.search(e.target.value);
    }
    setData((prevState) => ({
      ...prevState,
      result: searchedData,
    }));

    clearFilters();
  };

  const applyFilters = useCallback(() => {
    const retailers = filterSettings.retailerFilter.map((item) => item.value);
    const regions = filterSettings.regionFilter.map((item) => item.value);
    const fulfillmentCenters = filterSettings.fulfillmentCenterFilter.map(
      (item) => item.value,
    );
    const filteredData = data.initResult.filter((item) => {
      if (filterSettings.retailerFilter.length) {
        if (!retailers.includes(item.retailer)) {
          return false;
        }
      }
      if (filterSettings.regionFilter.length) {
        if (!regions.includes(item.region)) {
          return false;
        }
      }
      if (filterSettings.fulfillmentCenterFilter.length) {
        if (!fulfillmentCenters.includes(item.fulfillmentCenter)) {
          return false;
        }
      }
      return true;
    });
    setData((prevState) => ({
      ...prevState,
      result: filteredData,
    }));
  }, [
    filterSettings.fulfillmentCenterFilter,
    filterSettings.regionFilter,
    filterSettings.retailerFilter,
    data.initResult,
  ]);

  const clearSearchBoxes = () => {
    setLocationIdSearchValue('');
    setRouteIdSearchValue('');
  };

  const clearFilters = (hideData = false, clearSearch = false) => {
    if (
      filterSettings.retailerFilter.length ||
      filterSettings.regionFilter.length ||
      filterSettings.fulfillmentCenterFilter.length
    ) {
      setFilterSettings((prevState) => ({
        ...prevState,
        retailerFilter: [],
        regionFilter: [],
        fulfillmentCenterFilter: [],
      }));
    }
    if (hideData) {
      setShowData(false);
    }
    if (clearSearch) {
      clearSearchBoxes();
    }
  };

  const handleFilterChange = (filterType, value) => {
    if (value === null) value = [];
    clearSearchBoxes();
    setFilterSettings((prevState) => ({
      ...prevState,
      [filterType]: value,
    }));
  };

  const handleDateChange = (value) => {
    setDropOffDate({
      current: value,
      search: yyyymmdd(value, '-'),
    });
  };
  const handleDropOffDateSearch = async () => {
    setIsPageLoading(true);
    try {
      const locationRoutes = await GetLocationRoutes({
        stockingDate: dropOffDate.search,
      });
      // Filter wholesaleLocations based on matching IDs from locationRoutes
      const result = wholesaleLocations.filter((location) => {
        // Check if the current location's id exists in locationRoutes.routes
        const matchingRoute = locationRoutes.routes.find(
          (route) => route.id === location.id,
        );
        if (matchingRoute) {
          location.route = matchingRoute.route;
          location.region = matchingRoute.market;
          return true;
        }
        return false;
      });

      // Sort by route and then by location id
      result.sort((a, b) => {
        if (a.route < b.route) return -1;
        if (a.route > b.route) return 1;

        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;

        return 0;
      });

      setData({ initResult: result, result });

      setIsPageLoading(false);
      setShowData(true);
    } catch (e) {
      setIsPageLoading(false);
      console.error(e);
      toast.error(
        `Unable to load locations - Please try again later`,
        toastDefaultOptions,
      );
    }
  };

  const onClickTableEvent = (e) => {
    if (e.target.className === 'rowCheckbox') {
      onClickCheckbox(e);
    } else if (e.target.className === 'headerCheckbox') {
      handleSelectAll(e);
    } else if (e.currentTarget.className.includes('sort')) {
      onColumnSort(e.currentTarget.getAttribute('data-key'));
    }
  };

  const onClickCheckbox = (e, rowData) => {
    const isChecked = e.target.checked;
    const rowId = e.target.getAttribute('uniqueid');

    // Update selectedRows state based on the checkbox status
    if (isChecked) {
      setSelectedRows((prevState) => ({
        ...prevState,
        keys: [...prevState.keys, rowId], // Append rowId if checked
      }));
    } else {
      setSelectedRows((prevState) => ({
        ...prevState,
        keys: prevState.keys.filter((key) => key !== rowId), // Remove rowId if unchecked
      }));
    }

    setData((prevState) => ({
      ...prevState,
      initResult: prevState.initResult.map((item) => {
        if (item.id === rowId) {
          return { ...item, checked: isChecked };
        }
        return item;
      }),
      result: prevState.result.map((item) => {
        if (item.id === rowId) {
          return { ...item, checked: isChecked };
        }
        return item;
      }),
    }));
  };

  const sortArrayOfObjects = (arr, key, order) => {
    return arr.sort((a, b) => {
      if (a[key] === b[key]) {
        return 0;
      }
      if (a[key] == null) {
        return 1;
      }
      if (b[key] == null) {
        return -1;
      }
      if (order === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
  };

  const onColumnSort = (key) => {
    let order = 'asc';
    if (sortBy && sortBy.key === key) {
      order = sortBy.order === 'asc' ? 'desc' : 'asc';
    }
    const sortedData = sortArrayOfObjects(data.result, key, order);
    setSortBy({ key, order });
    setData((prevState) => ({
      ...prevState,
      result: sortedData,
    }));
  };

  return (
    <>
      {contextData.features['receiving-invoices'] ? (
        <StyledSection>
          {!showMessage && (
            <div className="invoicePageHeader">
              <span className="searchBoxes">
                <div>
                  <h1>
                    Receiving Invoices{' '}
                    {showData && (
                      <small className="">{`(${dropOffDate.search})`}</small>
                    )}
                  </h1>
                </div>
              </span>
              {showData && (
                <span>
                  <Button
                    style={{ marginTop: '15px' }}
                    bsStyle="warning"
                    bsSize="large"
                    disabled={isPageLoading || selectedRows.keys.length === 0}
                    onClick={() => setShowConfirmModel(true)}
                  >
                    Generate Invoices
                  </Button>
                </span>
              )}
            </div>
          )}
          {!showMessage && !showData && !isPageLoading && (
            <Grid>
              <Row className="text-center" style={{ marginBottom: '15px' }}>
                <div className="invoiceDatePickerWrapper">
                  <div className="datePickerLabel">Drop Off Date</div>
                  <DatePicker
                    data-test="Date-Picker"
                    onChange={handleDateChange}
                    value={dropOffDate.current}
                  />
                </div>
              </Row>
              <Row className="text-center" style={{ marginBottom: '15px' }}>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  disabled={isPageLoading}
                  onClick={handleDropOffDateSearch}
                >
                  Search
                </Button>
              </Row>
            </Grid>
          )}
          {!showData && (
            <>
              {showMessage && (
                <div className="successMessage">
                  <h1>Your request been successfully submitted</h1>
                  <Alert bsStyle="success">
                    <div>
                      Reference ID :{' '}
                      <span id="new-order-upload-id">{batchId}</span>
                    </div>
                  </Alert>
                  <Button
                    bsStyle="warning"
                    bsSize="large"
                    onClick={() => {
                      setShowMessage(false);
                      clearFilters(true, true);
                    }}
                  >
                    Generate New Invoices
                  </Button>
                </div>
              )}
              {!showMessage && isPageLoading && <Loading />}
            </>
          )}
          {showData && (
            <>
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={() => clearFilters(true, true)}
                className="dropOffDateBtnLarge"
              >
                Click to choose another date
              </Button>
              <div className="controlPanel">
                <span>
                  <span className="searchBoxes">
                    <span className="searchBoxesLabel">Filter: </span>
                    <Select
                      options={retailerList}
                      placeholder="Select Retailer"
                      getOptionLabel={(option) => option.value}
                      isMulti
                      hideSelectedOptions={false}
                      onChange={(e) => handleFilterChange('retailerFilter', e)}
                      value={
                        filterSettings.retailerFilter.length
                          ? filterSettings.retailerFilter
                          : null
                      }
                      maxMenuHeight={400}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: '6px',
                          border: '1px solid gray',
                          width: '200px',
                        }),
                      }}
                    />
                    <Select
                      options={regionsList}
                      placeholder="Select Region"
                      getOptionLabel={(option) => option.value}
                      isMulti
                      hideSelectedOptions={false}
                      onChange={(e) => handleFilterChange('regionFilter', e)}
                      value={
                        filterSettings.regionFilter.length
                          ? filterSettings.regionFilter
                          : null
                      }
                      maxMenuHeight={400}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: '6px',
                          border: '1px solid gray',
                          width: '200px',
                        }),
                      }}
                    />
                    <Select
                      options={fulfillmentCentersList}
                      placeholder="Select Market"
                      getOptionLabel={(option) => option.value}
                      isMulti
                      hideSelectedOptions={false}
                      onChange={(e) =>
                        handleFilterChange('fulfillmentCenterFilter', e)
                      }
                      value={
                        filterSettings.fulfillmentCenterFilter.length
                          ? filterSettings.fulfillmentCenterFilter
                          : null
                      }
                      maxMenuHeight={400}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: '6px',
                          border: '1px solid gray',
                          width: '250px',
                        }),
                      }}
                    />
                  </span>
                  <span className="searchBoxes">
                    <span className="searchBoxesLabel">Search: </span>
                    <input
                      type="text"
                      name="routeIdSearchBox"
                      className="searchBox"
                      onChange={handleSearching}
                      value={routeIdSearchValue}
                      placeholder="Route"
                    />
                    <input
                      type="text"
                      name="locationIdSearchBox"
                      className="searchBox"
                      onChange={handleSearching}
                      value={locationIdSearchValue}
                      placeholder="Location"
                    />
                  </span>
                </span>
              </div>
              <BaseTable
                className="receivingInvoicesTable"
                cellProps={{
                  onClick: (e) => onClickTableEvent(e),
                }}
                headerCellProps={{
                  onClick: (e) => onClickTableEvent(e),
                }}
                sortBy={sortBy}
                data={data.result}
                width={1140}
                height={580}
                rowKey="id"
                footerHeight={25}
                footerRenderer={customTableFooter}
              >
                <Column
                  title=""
                  key="checkbox"
                  dataKey="checkbox"
                  cellRenderer={cellRenderer}
                  headerRenderer={customCheckBoxHeader}
                  width={50}
                />
                <Column
                  title="Route"
                  key="route"
                  dataKey="route"
                  sortable
                  width={200}
                />
                <Column
                  title="Location ID"
                  key="id"
                  dataKey="id"
                  sortable
                  width={0}
                  flexGrow={1}
                />
                <Column
                  title="Retailer"
                  key="retailer"
                  dataKey="retailer"
                  sortable
                  width={100}
                />
                <Column
                  title="Region"
                  key="region"
                  dataKey="region"
                  sortable
                  width={100}
                />
                <Column
                  title="Market"
                  key="fulfillmentCenter"
                  dataKey="fulfillmentCenter"
                  sortable
                  width={200}
                />
              </BaseTable>
            </>
          )}
          <ConfirmationModal
            show={showConfirmModel}
            onHide={() => setShowConfirmModel(false)}
            onConfirm={() => onConfirmInvoiceGenerate()}
            title="Confirm Invoice Generation"
          >
            Please confirm to proceed generating invoices for the selected{' '}
            {selectedRows.keys.length} locations.
          </ConfirmationModal>
        </StyledSection>
      ) : (
        history.push('home')
      )}
    </>
  );
}
