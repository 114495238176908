import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import LoaderButton from '../components/LoaderButton';
import { Items, UploadQFSRejectsMutation } from '../libs/GraphQL';
import styledComponent from '../utils/styledComponent';
import ErrorsBlock from '../atoms/ErrorsBlock';
import FileUpload from '../atoms/FileUpload';
import Auth from '@aws-amplify/auth';
import Loading from '../atoms/Loading';

const LogModalTitle = styledComponent('h4', {
  borderBottom: '1px solid #e5e5e5',
  padding: '15px',
  margin: '0px',
});

const allowedFileColumns = [
  'ProductionDate',
  'Sku',
  'Stage',
  'Reason',
  'Quantity',
];
const notReqFileColumns = [];

const clearData = (data) => {
  // remove empty properties (skip columns with blank names)
  const clearedData = data.map((obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key !== '') {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });

  // remove lines that haven't enough columns (skip empty lines)
  const filteredData = clearedData.filter((item) => {
    return Object.keys(item).length >= allowedFileColumns.length;
  });

  return filteredData;
};

const ViewQFSRejects = () => {
  const [fileContent, setFileContent] = useState([]);
  const [allowedItemsNames, setAllowedItemsNames] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [lineErrors, setLineErrors] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [alertContent, setAlertContent] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  const isNumber = (value) => !Number.isNaN(Number(value));

  const hasErrors = fileErrors.length > 0 || lineErrors.length > 0;

  const getVendItems = async () => {
    try {
      const itemsNames = [];
      const rawVendItems = await Items();
      const activeVendItems = rawVendItems.data.items.filter(
        (item) => item.status === 'LIVE',
      );
      activeVendItems.forEach((item) => {
        itemsNames.push(item.id);
      });
      setAllowedItemsNames(itemsNames);
    } catch (e) {
      console.error(e);
    } finally {
      setInitLoading(false);
    }
  };

  useEffect(() => {
    getVendItems();
    getUserEmail();
  }, []);

  const getUserEmail = async () => {
    try {
      const currentSession = await Auth.currentAuthenticatedUser();
      const email = currentSession.signInUserSession.idToken.payload.email;
      if (!email.includes('@')) {
        throw new Error('There is no email present');
      } else {
        setUserEmail(email);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const validateFile = (file) => {
    const errors = [];
    const fileHeaders = file.meta.fields.filter((col) => col !== '');
    setFileColumns(fileHeaders);

    const columnsToValidate = fileHeaders.filter(
      (item) => !notReqFileColumns.includes(item),
    );

    const missedColumns = allowedFileColumns.filter(
      (x) => !columnsToValidate.includes(x),
    );
    const unknownColumns = columnsToValidate.filter(
      (x) => !allowedFileColumns.includes(x),
    );

    if (missedColumns.length) {
      errors.push(`Missed columns: ${missedColumns.join(', ')}`);
    }

    if (unknownColumns.length) {
      errors.push(
        `Unknown columns: ${unknownColumns.join(
          ', ',
        )}. File should contains following columns: ${allowedFileColumns.join(
          ', ',
        )}`,
      );
    }

    const fileData = clearData(file.data);

    const uniqueValues = new Set();

    for (const item of fileData) {
      const uniqueKey = `${item.ProductionDate}_${item.Sku}_${item.Stage}_${item.Reason}`;
      if (uniqueValues.has(uniqueKey)) {
        errors.push(
          `${item.Sku} is duplicated on ${item.ProductionDate} (${item.Stage}) with reason "${item.Reason}".`,
        );
      }
      uniqueValues.add(uniqueKey);
    }

    return errors;
  };

  const validateLine = (line, fileColumnsList) => {
    const errors = [];
    const today = moment().format('YYYY-MM-DD');

    // Validate `Production Date` column
    const productionDateMoment = moment(
      line.ProductionDate,
      'YYYY-MM-DD',
      true,
    );

    if (line.ProductionDate === '') {
      errors.push('Value required for `ProductionDate` column');
    } else if (!productionDateMoment.isValid()) {
      errors.push(
        '`ProductionDate` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    } else if (productionDateMoment.isAfter(today)) {
      errors.push('`ProductionDate` can not be after today');
    }

    // Validate `Sku` column
    if (line.Sku === '') {
      errors.push('Value required for `Sku` column');
    } else if (!allowedItemsNames.includes(line.Sku)) {
      errors.push(
        'The SKU invalid, probably SKU status is not marked as `LIVE`,',
      );
    }

    // Validate `Stage` column
    const stageAllowedValues = ['assembly', 'pickpack'];

    if (line.Stage === '') {
      errors.push('Value required for `Stage` column');
    } else if (!stageAllowedValues.includes(line.Stage)) {
      errors.push(
        `\`Stage\` column contains invalid value. Allowed values: ${stageAllowedValues.join(
          ', ',
        )}`,
      );
    }

    // Validate `Reason` column
    const reasonAllowedValues = [
      'Allergen Contamination',
      'Bad Seal',
      'Best Before Date Issue',
      'Body Fluid Contamination',
      'CCP Deviation',
      'Damaged / Broken',
      'Decay / Discoloration',
      'Discontinuation',
      'Environmental Contamination',
      'Expired',
      'Foreign Material Contamination',
      'Insect',
      'Missing / Incorrect Ingredient(s)',
      'Missing / Incorrect Label',
      'New launch',
      'Mold',
      'Over-prepped',
      'Sensory Issues',
      'Spilled / Dropped Product',
      'Temperature Abuse',
      'Unapproved Supplier',
      'Under / Over weight',
      'USDA Inspection Related',
    ];

    if (line.Reason === '') {
      errors.push('Value required for `Reason` column');
    } else if (isNumber(line.Reason)) {
      errors.push('`Reason` column should be a string');
    } else if (!reasonAllowedValues.includes(line.Reason)) {
      errors.push(`\`Reason\` column contains invalid value`);
    }

    // Validate `Quantity` column
    if (line.Quantity === '') {
      errors.push('Value required for `Quantity` column');
    } else if (!isNumber(line.Quantity)) {
      errors.push('`Quantity` column should be a number');
    } else if (line.Quantity <= 0) {
      errors.push('`Quantity` column should be greater than 0');
    }

    return errors;
  };

  const handleFileChange = (file) => {
    setFileErrors([]);
    setLineErrors([]);
    setFileChosen(false);
    setFileContent([]);
    setFileName(file.name);

    if (file.type !== 'text/csv') {
      setFileErrors(['File must be a .csv']);
    } else {
      window.Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (fileData) => {
          const filteredData = clearData(fileData.data);
          const fileValidationErrors = validateFile(fileData);
          if (fileValidationErrors.length) {
            setFileErrors(fileValidationErrors);
          } else {
            const linesValidationErrors = [];
            filteredData.forEach((line, i) => {
              const lineValidationErrors = validateLine(
                line,
                fileData.meta.fields,
              );
              if (lineValidationErrors.length) {
                linesValidationErrors.push({
                  location: `[Line ${i + 2} / ${line.Sku} / ${
                    line.ProductionDate
                  }]`,
                  data: lineValidationErrors,
                });
              }
            });

            if (!linesValidationErrors.length) {
              setFileContent(fileData);
            } else {
              setLineErrors(linesValidationErrors);
            }
          }
        },
      });
    }
    setFileChosen(true);
  };

  const formatFileContent = () => {
    const formattedFileContent = [];
    clearData(fileContent.data).forEach((line) => {
      const formattedLine = {
        productionDate: moment(line.ProductionDate).format('YYYY-MM-DD'),
        sku: line.Sku,
        quantity: line.Quantity,
        stage: line.Stage,
        reason: line.Reason,
      };
      formattedFileContent.push(formattedLine);
    });
    return formattedFileContent;
  };

  const handleUpload = async () => {
    setLoading(true);
    const alertMessages = [];

    try {
      await UploadQFSRejectsMutation(formatFileContent(), userEmail);
      alertMessages.push(
        `QFS Rejects Uploaded Successfully for ${
          clearData(fileContent.data).length
        } items`,
      );
    } catch (e) {
      console.error('QFS Rejects Uploading Error:', e);
      e.errors.forEach((error) => {
        alertMessages.push(`Server Error: ${error.message}`);
      });
    } finally {
      setAlertContent(alertMessages);
      setFileContent([]);
      setFileChosen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <h1>Upload QFS Rejects</h1>
      {initLoading ? (
        <div>
          <Loading />
        </div>
      ) : fileChosen ? (
        <Button
          block
          bsStyle="primary"
          bsSize="large"
          style={{ marginBottom: '40px' }}
          disabled={loading}
          onClick={() => {
            setFileErrors([]);
            setLineErrors([]);
            setFileChosen(false);
            setFileContent([]);
          }}
        >
          Upload another file
        </Button>
      ) : (
        <FileUpload onFileChange={handleFileChange} />
      )}
      {}

      {hasErrors && (
        <ErrorsBlock>
          <ul>
            {fileErrors.map((error) => {
              return <li key={error}>{error}</li>;
            })}
            {lineErrors.map((error) => {
              return (
                <li key={error.location}>
                  <b>{error.location} has an error</b> <br />
                  {error.data.map((msg) => {
                    return <div key={msg}> {msg} </div>;
                  })}
                </li>
              );
            })}
          </ul>
        </ErrorsBlock>
      )}

      {fileContent.length !== 0 && !hasErrors && (
        <>
          <span>{fileName}</span>
          <Table>
            <thead style={{ border: '1px solid #ddd' }}>
              <tr>
                {fileColumns.map((col) => {
                  return <th key={col}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {fileContent &&
                clearData(fileContent.data).map((line) => {
                  return (
                    <tr key={`${line.SKU}_${line['Production Date']}`}>
                      {fileColumns.map((col) => {
                        return (
                          <td key={col}>
                            {typeof line[col] === 'boolean'
                              ? line[col].toString()
                              : line[col]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="success"
            bsSize="large"
            type="submit"
            isLoading={loading}
            text="Upload QFS Rejects"
            loadingText="QFS Rejects Loading…"
            style={{ marginBottom: '40px' }}
            onClick={handleUpload}
          />
        </>
      )}

      <Modal show={alertContent.length}>
        <LogModalTitle>Production Plan Upload Log</LogModalTitle>
        <Modal.Body>
          {alertContent.map((msg) => (
            <div key={msg} style={{ marginBottom: '8px' }}>
              {msg}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            onClick={() => {
              setAlertContent([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewQFSRejects;
