import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { ReRunAllocation } from '../libs/GraphQL';
import Select from '../atoms/forms/Select';

const packingSlipGroupOptions = [
  {
    label: 'MW',
    value: 'MW',
  },
];

const RunAllocation = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [packingSlipGroup, setPackingSlipGroup] = useState('MW');
  const [isRunBlocked, setIsRunBlocked] = useState(true);

  useEffect(() => {
    // blocking re-run if it already was less than `blockForMinutes` minutes ago
    const lastReRunTimestamp = localStorage.getItem('allocationReRunTimestamp');
    if (lastReRunTimestamp) {
      const currentTime = moment().valueOf();
      const blockForMinutes = 5;
      const differenceInMinutes = moment
        .duration(currentTime - lastReRunTimestamp)
        .minutes();
      setIsRunBlocked(differenceInMinutes <= blockForMinutes);
    } else {
      setIsRunBlocked(false);
    }
  }, []);

  const handleRunAllocationClick = async () => {
    setIsShowModal(false);
    setIsLoading(true);
    try {
      await ReRunAllocation(packingSlipGroup);
      const currentTime = moment().valueOf();
      localStorage.setItem('allocationReRunTimestamp', currentTime);
      setIsRunBlocked(true);
    } catch (error) {
      console.log(error);
      if (error.errors && error.errors[0].errorType === 'ExecutionTimeout') {
        console.log('AppSync time out - this is a non-blocking error');
      } else {
        alert(
          'We had issues re-running allocation. Please contact the on-call engineer.',
        );
        return;
      }
    }
    setIsLoading(false);
    alert(
      'Re-run of allocation has been started. Please check the allocation email to confirm a successful re-run.',
    );
  };
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Modal show={isShowModal}>
        <Modal.Body>
          <h3>Are you sure you want to re-run allocation?</h3>
          <br />
          <b>Select a region</b>
          <Select
            required={false}
            label=""
            id="packingSlipGroup"
            options={packingSlipGroupOptions}
            value={packingSlipGroup}
            onChange={(region) => setPackingSlipGroup(region.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-warning"
            onClick={handleRunAllocationClick}
          >
            Re-Run Allocation
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setIsShowModal(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <h3>Fridge Allocation</h3>
      <Button
        onClick={() => {
          setIsShowModal(true);
        }}
        disabled={isLoading || isRunBlocked}
        title={
          isRunBlocked &&
          'Re-running allocation can only occur once every 5 minutes. Please try again later'
        }
      >
        {isLoading ? 'Loading...' : 'Re-Run Allocation'}
      </Button>
    </div>
  );
};

export default RunAllocation;
