/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@farmers-fridge/ui-toolbox';
import Select from '../../atoms/forms/Select';
import styledComponent from '../../utils/styledComponent';
import DatePicker from '../../atoms/forms/DatePicker';
import {
  CreateItemSubstitutionRuleMutation,
  Items,
} from '../../libs/GraphQL.js';
import { toast } from 'react-toastify';
import { sortArrayOfObjects } from '../../libs/utility';

const StyledSection = styledComponent('section', {
  '& h1': {
    color: '#457A23',
  },
  '& h2': {
    color: '#565656',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  '& li': {
    listStyle: 'none',
  },
  '& .readOnlyLabel': {
    color: '#000',
    fontSize: '18px',
    fontWeight: '800',
    width: '250px',
  },
  '& .readOnlyValue': {
    '& a': {
      color: '#5F8E40',
      marginLeft: '10px',
    },
    color: '#818181',
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '20px',
  },
  '& ul': {
    margin: 0,
    padding: 0,
  },
  marginBottom: '50px',
  '& .error': {
    color: '#d32c60',
    padding: '5px 0',
  },
});

const ItemSubstitutionRuleAdd = ({ handleClose }) => {
  const data = {};
  const [vendItems, setVendItems] = useState([]);

  const getLiveVendItems = async () => {
    try {
      let liveItems = await Items();
      setVendItems(sortArrayOfObjects(liveItems.data.items, 'id', 'asc'));
    } catch (e) {
      console.log(e);
      toast.error('Failed to load the Vend Items. Please try again');
    }
  };

  useEffect(() => {
    if (vendItems.length === 0) {
      getLiveVendItems();
    }
  }, []);

  const close = async () => {
    handleClose();
  };

  const AddSchema = Yup.object().shape({
    originalItemId: Yup.string().required('Required'),
    substitutionItemId: Yup.string().required('Required'),
    expirationDate: Yup.date().required('Required'),
  });

  const tomorrow = moment().add(1, 'day');
  const disablePastDates = (current) => {
    return tomorrow.isAfter(current);
  };

  return (
    <StyledSection>
      <h1>Active Item Substitution Rules</h1>
      <h2>Creating Item Substitution Rule</h2>
      <Formik
        initialValues={{
          expirationDate: data.date || moment().format('YYYY-MM-DD'),
          originalItemId: '',
          substitutionItemId: '',
        }}
        validationSchema={AddSchema}
        onSubmit={async (values, { setSubmitting }) => {
          CreateItemSubstitutionRuleMutation(values)
            .then((res) => {
              toast.success('Item Substitution Rule Created');
              close();
            })
            .catch((err) => {
              if (err.errors && err.errors) {
                err.errors.forEach((error) => {
                  toast.error(error.message);
                });
              } else {
                console.log(err);
                toast.error('Please try again later');
              }
            });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <form data-testid="locomotiveAdd-form" onSubmit={handleSubmit}>
              <ul>
                <li>
                  <DatePicker
                    error={errors.expirationDate}
                    label="Expiration Date"
                    isDayBlocked={disablePastDates}
                    onChange={(v) => {
                      setFieldValue(
                        'expirationDate',
                        moment(v).format('YYYY-MM-DD'),
                      );
                    }}
                  />
                </li>
                <li>
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Original Item"
                    id="originalItem"
                    options={vendItems.map((vendItem) => ({
                      value: vendItem.id,
                      label: vendItem.prettyName,
                    }))}
                    onChange={(v) => {
                      setFieldTouched('originalItem', true);
                      setFieldValue('originalItemId', v.value);
                    }}
                  />
                  <div>
                    {errors.originalItemId && touched.originalItemId ? (
                      <div className="error">{errors.originalItemId}</div>
                    ) : null}
                  </div>
                </li>
                <li>
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Substitution Item"
                    id="substitutionItem"
                    options={vendItems.map((vendItem) => ({
                      value: vendItem.id,
                      label: vendItem.prettyName,
                    }))}
                    onChange={(v) => {
                      setFieldTouched('substitutionItem', true);
                      setFieldValue('substitutionItemId', v.value);
                    }}
                  />
                  <div>
                    {errors.substitutionItemId && touched.substitutionItemId ? (
                      <div className="error">{errors.substitutionItemId}</div>
                    ) : null}
                  </div>
                </li>
              </ul>
              <button
                className="button"
                disabled={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
                type="button"
              >
                Add Rule
              </button>
            </form>
          );
        }}
      </Formik>
    </StyledSection>
  );
};

export default ItemSubstitutionRuleAdd;
