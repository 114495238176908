import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Glyphicon, Nav, Navbar, NavDropdown, MenuItem } from 'react-bootstrap';
import Routes from './Routes';
import { Auth } from 'aws-amplify';
import { invokeApig } from './libs/awsLib';
import packageSetting from '../package.json';
import { ContextProvider } from './ContextProvider';
import Features from './Features';
import NavItems from './organisms/NavItems';
import { identify } from './utils/analytics';

class App extends Component {
  constructor(props) {
    super(props);
    this.updateHighlight = this.updateHighlight.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    let highlight = window.location.pathname.split('/')[1].toLowerCase();

    this.state = {
      currentKey: highlight,
      views: null,
      authState: this.props.authState,
      position: { latitude: null, longitude: null, status: 'UNKNOWN' },
      staleVersion: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authState !== state.authState) {
      return {
        authState: props.authState,
      };
    }
    return null;
  }

  async componentDidMount() {
    try {
      const currentSession = await Auth.currentAuthenticatedUser();
      console.log('Authenticated user:', currentSession);
      this.setState({
        session: currentSession,
        user: currentSession.signInUserSession.idToken.payload.email,
      });
      identify(currentSession.signInUserSession.idToken.payload.email);
      this.intervalId = setInterval(this.determineVersion.bind(this), 300000); // timer is for 5 min
    } catch (error) {
      console.error('Authentication error caught in componentDidMount:', error);
      this.setState({ session: null, user: null });
    }
  }

  async componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.authState !== this.props.authState) {
      try {
        const views = await this.views();
        this.setState({ views: views.body });

        const currentSession = await Auth.currentAuthenticatedUser();
        this.setState({
          user: currentSession.signInUserSession.idToken.payload.email,
        });
      } catch (e) {
        var errorString = JSON.stringify(e);
        console.error(errorString);
      }
    }
  }

  determineVersion() {
    fetch(window.location.href, { cache: 'no-store' })
      .then((response) => response.text())
      .then((response) => {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(response, 'text/html');
        var oldScripts = htmlDoc.getElementsByTagName('script');
        var newScripts = document.getElementsByTagName('script');
        let oldScriptToCompare = '';
        let newScriptToCompare = '';
        for (var i = 0; i < oldScripts.length; i++) {
          if (oldScripts[i].src && oldScripts[i].src.includes('main')) {
            oldScriptToCompare = oldScripts[i].src;
          }
        }
        for (var i = 0; i < newScripts.length; i++) {
          if (newScripts[i].src && newScripts[i].src.includes('main')) {
            newScriptToCompare = newScripts[i].src;
          }
        }
        if (
          oldScriptToCompare !== newScriptToCompare &&
          this.state.staleVersion === false
        ) {
          this.setState({ staleVersion: true });
        }
      })
      .catch((err) => console.log(err));
  }

  handleLogout() {
    Auth.signOut()
      .then(() => {
        this.props.onStateChange('signedOut', null);
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  }
  views() {
    return invokeApig({
      path: '/views',
      method: 'GET',
    });
  }
  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  updateHighlight(key) {
    this.setState({ currentKey: key });
  }

  displayToast() {
    toast.info(
      'You are on an old version of the software. Please refresh the page.',
      { autoClose: false },
    );
  }

  render() {
    if (this.props.authState !== 'signedIn') {
      return null;
    }
    const childProps = {
      isAuthenticated: true,
      userHasAuthenticated: this.userHasAuthenticated,
      views: this.state.views,
      userEmail: this.state.user,
    };
    return (
      <div
        data-test="parent-app"
        className="App container"
        style={{ marginTop: '15px' }}
      >
        {this.state.staleVersion && this.displayToast()}

        <ContextProvider>
          <Features user={this.state.session}>
            <Navbar fluid collapseOnSelect>
              <Navbar.Header>
                <Navbar.Brand>
                  <Link
                    onClick={(e) => {
                      this.updateHighlight(4);
                    }}
                    to="/home"
                    style={{ fontWeight: 'bold' }}
                  >
                    Home
                  </Link>
                </Navbar.Brand>
                <Nav>
                  <NavDropdown
                    key={'internalApps'}
                    eventKey={'internalApps'}
                    title="Internal Apps"
                    style={{}}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://cms.farmerfridge.com/', '_blank');
                      }}
                      eventKey={6.1}
                    >
                      {'Location CMS'}
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          'https://driver.farmerfridge.com/',
                          '_blank',
                        );
                      }}
                      eventKey={6.2}
                    >
                      {"Driver's App"}
                    </MenuItem>
                  </NavDropdown>
                </Nav>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>
                <Nav activeKey={this.state.currentKey} pullRight>
                  <NavItems history={this.props.history} />
                  <NavDropdown
                    key={'user'}
                    eventKey={'user'}
                    title="User"
                    id="basic-nav-dropdown"
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleLogout();
                      }}
                      eventKey={1.1}
                    >
                      Log Out
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem disabled eventKey={1.3}>
                      User: {this.state.user}{' '}
                    </MenuItem>
                    <MenuItem disabled eventKey={1.2}>
                      Location Services:{' '}
                      {this.state.position.status.startsWith('UNKNOWN') && (
                        <Glyphicon glyph="question-sign" />
                      )}{' '}
                      {this.state.position.status.startsWith('OK') && (
                        <Glyphicon glyph="ok-sign" />
                      )}
                      {this.state.position.status.startsWith('ERROR') && (
                        <Glyphicon glyph="remove-sign" />
                      )}
                    </MenuItem>
                    <MenuItem disabled eventKey={1.0}>
                      Version: {packageSetting.version}
                    </MenuItem>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Routes childProps={childProps} />
            <ToastContainer />
          </Features>
        </ContextProvider>
      </div>
    );
  }
}

export default withRouter(App);
